<template>
  <CModal
    :title="$t('edit_config')"
    size="md"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12" v-if="formData.type === 'textarea'">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-12">
            {{ formData.name }}
          </label>
          <div class="col-sm-12">
            <textarea-autosize
              :placeholder="formData.name"
              :class="['form-control', valid_formData_v ? 'is-valid' : 'is-invalid']"
              v-model="formData.v"
              :min-height="100"
              :max-height="350"
            />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12" v-else>
        <CInput
          :label="formData.name"
          :placeholder="formData.name"
          :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
          v-model="formData.v"
          :isValid="valid_formData_v"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditConfigModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showConfig()
      }
    }
  },
  computed: {
    valid_formData_v () {
      return this.formData.v ? true : false
    },
    valid_submit () {
      return this.valid_formData_v
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showConfig () {
      const loader = this.$loading.show()
      this.$store.dispatch('showConfig', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateConfig', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>

<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10"></CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getConfigs()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2"></CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="v" slot-scope="props">
              {{ props.row.v && props.row.v.length > 70 ? props.row.v.substring(0, 70) + '...' : props.row.v }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editConfigModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <EditConfigModal :data="editConfigModal.data" :show="editConfigModal.modal" @showUpdate="show => editConfigModal.modal = show" @formSubmit="getConfigs()" />

    </CCol>
  </CRow>
</template>

<script>
import EditConfigModal from './modal/EditConfigModal'

export default {
  name: 'configs',
  components: {
    EditConfigModal,
  },
  data () {
    return {
      columns: [ 'name', 'v', 'action' ],
      data: [],
      options: {
        headings: {
          name: this.$t('name'),
          email: this.$t('value'),
          action: this.$t('action')
        },
        sortable: [ 'name', 'v', 'enabled' ],
        filterable: [ 'name', 'v', 'enabled' ]
      },
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { name: '', email: '' },
      editConfigModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getConfigs()
  },
  methods: {
    getConfigs () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getConfigs', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
